import React from "react";
import { Link } from "react-router-dom";

interface Props {
  className?: string;
}

export default function Navbar(props: Props) {
  return (
    <div className={"min-h-[50px] flex " + props.className}>
      <div className="max-w-5xl mx-auto px-2 flex flex-row justify-around w-full m-auto">
        <div className="logo justify-between mt-auto lg:text-3xl text-2xl font-bold text-secondary">
          {/* add logo here */}
          <div className="flex">
            <img src="/logo512_inv.png" width={50} alt="" />
          Edu<i className="text- font-extrabold ">former</i>
          </div>
        </div>
        <Link
          className="home-btn text-sm mt-auto cursor-pointer hover:text-gray-900 text-gray-500q font-bold"
          to="/"
        >
          HOME
        </Link>
        <Link
          className="home-btn text-sm mt-auto cursor-pointer hover:text-gray-900 text-gray-500q font-bold"
          to="/"
        >
          LOGIN
        </Link>
        <Link
          className="about-btn text-sm mt-auto cursor-pointer hover:text-gray-900 text-gray-500q font-bold"
          to={"/about"}
        >
          ABOUT
        </Link>
      </div>
    </div>
  );
}
