export default function Landing() {
  return (
    <div className="body-container relative w-[80%] h-[100%] m-auto flex items-center justify-center">
      {/* div that contains an image */}
      <div className="landing-image-container w-[60%]">
        <img
          src="/images/art_02.png"
          alt="landing-img"
          className="object-cover"
        />
      </div>
      {/* create a simple landing page that has a text on the left and an image on the right*/}
      <div className="landing-box w-1/2 h-fit mb-24 px-12 py-8 rounded-md z-50">
        <div className="landing-box-header">
          {/* <img
            src="/images/main_logo.png"
            alt="landing-img"
            className="object-cover w-3/4 m-auto"
          /> */}
          <h1 className="text-6xl font-bold my-4">EduFormer</h1>
          <p className="text-4xl my-4">Learn Intelligently</p>
          <div className="text-justify text-base text-gray-500">
            We understand that everyone learns differently and at their own
            pace, which is why we've developed cutting-edge AI technology to
            help you learn faster and more efficiently than ever before. Our
            mission is to empower learners of all ages and backgrounds to
            achieve their educational goals and unlock their full potential.
            Trust us to be your partner in your learning journey, and together
            we'll create a brighter future.
          </div>
        </div>
      </div>
    </div>
  );
}
