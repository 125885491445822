import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/footer';
import Navbar from './components/navbar/navbar';
import Landing from './pages/landing/landing';

function App() {
  return (
    <Router>
      <div className="App h-screen flex flex-col justify-between">
        <Navbar className='h-[5%] block' />
        <Routes>
          <Route path='/' element={<Landing />} />
          {/* <Route path='/home' element={<Home />} /> */}
          {/* <Route path='/coding' element={<CodingHome />} />
          <Route path='/about' element={<About />} /> */}
        </Routes>
        <Footer className='h-[5%] z-[99] bottom-0 w-full lg:realtive fixed' />
      </div>
    </Router>
  );
}

export default App;