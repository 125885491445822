import React from 'react'

interface Props {
  className?: string
}

export default function Footer(props: Props) {
  return (
    <div className={'bg-gray-50 min-h-[30px] fixed flex justify-center' + props.className}>
      <div className='text-gray-400 text-xs h-fit w-fit m-auto'>
          2023&copy; EduFormer. All Rights Reserved.
        </div>
    </div>
  )
}
